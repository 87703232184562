<template lang="pug">
div(fluid).candidate.pt-8
  v-tabs(v-model="activeTab" background-color="transparent")
    v-tab Details
    v-tab Exam history
  .text-center.pa-10(v-if="loading" data-test="candidate-loading")
      v-progress-circular(indeterminate size=64 color="warning")
  v-container(fluid :class="{'candidate__wrapper_no-spacing': isExamHistoryTab}").candidate__wrapper
    .candidate__main(v-if="!loading && model && isMainTab" data-test="candidate-item")
      v-form(ref="form")
        fields(
          :model="model"
          :formErrors="formErrors"
          ref="fields"
          :goBack="goBack"
          @update:data="updateData"
          @needToUpdate="$emit('needToUpdate')"
          @checkReservationClicked="checkReservationQueue"
          @reservedExam:update="resetReservations"
          @candidateNumberChanged="markCandidateNumberAsChanged"
          @labelsChanged="assignLabels"
          @parseReserveNumber="parseReserveNumber"
          @change:field="fieldChanged"
        )
        reservations(
          v-if="model.filteredRequests"
          :model="model"
          ref="reservations"
          :formErrors="formErrors"
          :disabled="isArchived"
          @remove="removeReservationRequest"
          @orderingChanged="saveRequestsAfterDragging"
          @changed="fieldChanged({field: 'requests', value: model.filteredRequests})"
          :getExamLocation="getExamLocation"
          :getCourseLocation="getCourseLocation"
          v-slot="reservations"
        ).item-container
          .pl-4.pr-4
            v-row.item-container
              v-col(cols=6)
                .d-flex.align-center
                  h3.form-title.mr-4 Exam Details
                  v-btn(
                    outlined
                    small
                    color="primary"
                    @click="reservations.reset"
                  ) Clear all

    .candidate__main(v-if="isExamHistoryTab")
      exam-history(v-if="model && model.exams" :list="model.exams" @updating:willCome="updateExamItem")
    .candidate__additional(v-if="!loading && model")
      div.candidate__additional-wrapper
        send-log-field(:ID="ID" v-if="logs")
        logs(:value="logsShowing" v-if="logs" @click:loadMore="loadLogsMore" :model="logs" ref="log")
          template(v-slot:header="props")
            logsTabs(:model="props.model" :loadData="props.loadData" :getDefaultPaginationParams="props.getDefaultPaginationParams")
          template(v-slot:candidateName="{candidate, fullName}")
            span.link {{ fullName }}
            span.link - {{ candidate.number }}
        candidate-courses-history(:courses="model.exam_request_history" @makeActive="addFilledReservations")

  .candidate__actions
    .candidate__actions-wrapper
      similar(:ID="model.ID" :candidateNumber="model.candidateNumber" v-if="model").ml-4
      v-spacer
      v-btn(@click="goBackHandler" outlined color="primary" data-test='candidate_cancel').mr-4 cancel
      update-action(
        :model="model"
        :formErrors="formErrors"
        :updateRequests="updateRequests"
        :candidateNumberChanged="candidateNumberChanged"
        @submit="update"
      )

  confirmation-dialog(
    v-model="leaveModalShowing"
    @okBtnClicked="goBackAccept"
    width="420px"
    title="You have unsaved candidate data"
    descr="Are you sure you want to close the candidate? All data that you did not save will be lost."
  )
</template>

<script>
import {mapGetters, mapActions} from "vuex"

import Svc from '../../core/candidates-service'
import Model from '../../core/candidates-reservation-model'
import FormErrors from '@/util/form-errors'
import ErrMixin from '@/mixins/errors.mixin'
import { LOG_PAGE } from '@/util/const'
import CandidateLogModel from '../../core/models/candidatesLogModel'

import Logs from '@/app/admin/components/Log.vue'
import Tabs from '../../components/CandidateItemTab.vue'
import Fields from '../../components/CandidateItemFields.vue'
import { API_ATTRIBUTES, API_ATTRIBUTES_NESTED, CBR_STATUS } from '../../core/candidates-const'
import ResultsService from '../../../results/core/results-service'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'CandidatesItemPage',

  mixins: [ErrMixin, featureFlagsMixin],

  props: {
    query: Object,
    ID: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    svc: null,
    model: null,
    loading: true,
    formErrors: null,
    reservationTab: null,
    logs: null,
    logsShowing: false,
    editing: false,
    leaveModalShowing: false,
    closeCallback: null,
    candidateNumberChanged: false,
    activeTab: 0,
    changedFields: {}
  }),

  computed:{
    ...mapGetters({
      currentUser: 'user/current'
    }),

    isArchived() {
      return this.model.status === CBR_STATUS.ARCHIVED
    },

    isMainTab() {
      return this.activeTab === 0
    },

    isExamHistoryTab() {
      return this.activeTab === 1
    }
  },

  created() {
    this.init()
  },

  async mounted() {
    await this.loadData()
    this.loadAdditionalData()
    this.processAfterDataLoading()
    this.addHotkeyToSave()
    this.loadLogs()
  },

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list'
    }),

    async updateData(payload) {
      try {
        this.loading = true
        let res = await this.svc.patchCandidate(this.ID, payload)
        this.model.merge(new Model(res))
        this.$notify({text: 'Updated', type: 'success'})
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },

    addFilledReservations(reservations) {
      this.model.addFilledReservationRequests(reservations)
      this.$refs.reservations && this.$refs.reservations.setRequests()
    },

    init() {
      this.svc = new Svc()
      this.formErrors = new FormErrors()
      this.initLogs(this.currentUser.logsWebsocketPath)
    },

    async parseReserveNumber(id) {
      await this.svc.parseReservationNumber(id)
    },

    async initLogs(wsURL) {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.logs = new CandidateLogModel({
        candidate: this.ID,
        page: [LOG_PAGE.CONTANCT]
      }, `${wsURL}?auth_token=${tokens.access}`)
      this.logs.ws.addEventListener("message", this.addToCandidate)
      this.logsShowing = true
    },

    addToCandidate(msg) {
      this.logs.addToCandidate(msg, this.onLogUpdate)
    },

    async loadData() {
      try {
        this.loading = true
        let res = await this.svc.find(this.ID)
        this.model = new Model(res)
        this.loading = false
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    loadAdditionalData() {
      this.getExamLocation({cache: true})
    },

    processAfterDataLoading() {
      if (!this.$refs.fields || !this.$refs.fields.disabled) return
      try {
        this.$refs.fields.disabled.checkCBRStatus(this.model.status)
      } catch (e) {
        console.log(e)
      }
    },

    async update() {
      try {
        // if(this.featureFlags[this.FEATURE_FLAG.NCRM_3739]) return this.updateWithPatch()
        this.updateRequests()
        let res = await this.svc.put(this.ID, this.model)
        this.model.editing = false
        this.model.merge(new Model(res))
        setTimeout(() => {
          this.setReservationsRequest()
        }, 0)
        this.$notify({text: 'Updated', type: 'success'})
        this.$emit('saved', this.model)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: API_ATTRIBUTES,
          nestedErrors: API_ATTRIBUTES_NESTED,
          showErrorModal: true
        })
        this.handlingErrorWithOldDates(this.formErrors)
      }
    },

    // async updateWithPatch() {
    //   try {
    //     //  Todo need global refactor
    //     this.updateRequests()
    //     if('requests' in this.changedFields) this.changedFields.requests = this.model.filteredRequests
    //     let res = await this.svc.patchCandidate(this.ID, this.changedFields)
    //     this.model.editing = false
    //     this.changedFields = {}
    //     this.model.merge(new Model(res))
    //     setTimeout(() => {
    //       this.setReservationsRequest()
    //     }, 0)
    //     this.$notify({text: 'Updated', type: 'success'})
    //     this.$emit('saved', this.model)
    //   } catch (error) {
    //     console.log(error)
    //     this.processErrorWithForm(error, {
    //       redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
    //       formErrors: this.formErrors,
    //       apiAttributes: API_ATTRIBUTES,
    //       nestedErrors: API_ATTRIBUTES_NESTED,
    //       showErrorModal: true
    //     })
    //     this.handlingErrorWithOldDates(this.formErrors)
    //   }
    // },

    updateRequests() {
      if(!this.$refs.reservations) return;
      this.model.requests = this.$refs.reservations.requests
    },

    handlingErrorWithOldDates(formErrors) {
      if ('courseDate' in formErrors.fields) formErrors.fields.courseDate = 'Please select a course'
      if (!('requests' in formErrors.fields)) return
      formErrors.fields.requests.forEach(field => {
        if (!(('courseDate') in field)) return
        field.courseDate = 'Please select a course'
      })
    },

    async saveRequestsAfterDragging() {
      try {
        let res = await this.svc.updateReservations(
          this.ID,
          this.model._getRequestsApiDataBy(this.$refs.reservations.requests)
        )
        this.$notify({text: 'Ordering changed', type: 'success'})
      } catch (error) {
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: API_ATTRIBUTES,
          nestedErrors: API_ATTRIBUTES_NESTED
        })
      }
    },

    async checkReservationQueue() {
      try {
        let res = await this.svc.checkReservationQueueBulk([this.ID])
        this.model.failed = false
        this.$notify({text: 'Try to reserve', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    addReservationRequest() {
      this.model.addReservationRequest()
    },

    removeReservationRequest(index) {
      this.model.removeReservationRequest(index)
    },

    goBackHandler() {
      if (this.model.editing) {
        this.leaveModalShowing = true
        return
      }
      this.goBack()
    },

    goBack() {
      this.model.editing = false
      this.changedFields = {}
      this.$emit('close', this.ID)
    },

    goBackAccept() {
      this.goBack()
      if (this.closeCallback) this.closeCallback()
    },

    loadLogs() {
      this.$refs.log && this.$refs.log.loadData({
        ...this.$refs.log.getDefaultPaginationParams()
      })
    },

    loadLogsMore() {
      this.$refs.log && this.$refs.log.loadMore({
      })
    },

    addHotkeyToSave() {
      document.addEventListener('keyup', this.saveOnHotkey, false);
    },

    saveOnHotkey(e) {
      e.preventDefault()
      if (e.ctrlKey && e.shiftKey && e.keyCode == 83) {
        this.update()
      }
    },

    onLogUpdate(msg) {
      this.$notify({text: msg.comment, type: msg.status, title: msg.candidate.name })
    },

    startEditing() {
      this.editing = true
    },

    setReservationsRequest() {
      this.$refs.reservations && this.$refs.reservations.addRequestIfNoAvailable()
    },

    resetReservations() {
      this.$refs.reservations && this.$refs.reservations.resetRequests()
    },

    async markAsProcessed(items) {
      try {
        let ids = [this.model.ID]
        await this.svc.markAsProcessed(ids)
        this.$notify({text: 'Mark as processed', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        return Promise.reject(error)
      }
    },

    markCandidateNumberAsChanged() {
      this.candidateNumberChanged = true
    },

    async assignLabels(labels) {
      this.model.labels = labels
      // this.update()
      try {

        this.model.labels = labels
        await this.svc.updateLabels(this.ID, this.model.labels)
        this.$notify({text: 'Labels assigned', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
        return Promise.reject(error)
      }
    },

    async updateExamItem({field, value, ID}) {
      try {
        let exams = JSON.parse(JSON.stringify(this.model.exams))
        let examItem = exams.find(exam => exam.id === ID)
        if(!examItem) return this.$notify({text: 'Something wrong and exam not updated', type: 'error'})
        examItem[field] = value
        let res = await new ResultsService().updateWillCome(ID, value)
        this.model.exams = exams
        this.$emit('saved')
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    fieldChanged({field, val}) {
      this.changedFields[field] = val
    }
  },

  beforeDestroy() {
    this.logs.ws.removeEventListener("message", this.addToCandidate)
    this.logs.ws.close()
  },

  provide: function() {
    return {
      svc: () => this.svc,
      startEditing: this.startEditing,
      markAsProcessed: this.markAsProcessed
    }
  },

  components: {
    courseLocationFilter: () => import ('@/app/admin/components/FilterCourseLocaiton.vue'),
    reservations: () => import('./CanditeReservations.vue'),
    fields: Fields,
    tabs: Tabs,
    logs: Logs,
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    updateAction: () => import('./CandidateItemUpdate.vue'),
    similar: () => import('./CandidateSimilar.vue'),
    logsTabs: () => import('./CandidateLogTab.vue'),
    sendLogField: () => import('./CandidateSendLogField.vue'),
    CandidateCoursesHistory: () => import('./CandidateCoursesHistory.vue'),
    examHistory: () => import('./CandidateItemExamHistory.vue')
  }
}
</script>

<style lang="scss" scoped>
.candidate {
  &__main {
    min-width: 916px;
    max-width: 916px;
  }
  &__additional {
    width: 100%;
    &-wrapper {
      width: 400px;
    }
  }
  &__actions {
    position: fixed;
    bottom: 0;
    z-index: 10;
    right: 0;
    left: auto;
    width: calc(100% - 48px);
    border-top: 1px solid $border-color;
    &-wrapper {
      display: flex;
      padding: 20px 12px 20px 0;
      background: #FFFFFF;
    }
  }
  &__wrapper {
    display: flex;
    &_no-spacing {
      padding-left: 0 !important;
    }
  }
}

.log-btn {
  position: absolute;
  top: -70px;
  right: 20px;
}

</style>

<style lang="scss">
.candidate {
  .app-table table {
    min-width: auto;
    min-width: 916px !important;
    width: 916px !important;
    table-layout: initial;
  }
}

</style>
