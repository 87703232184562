<template lang="pug">
.pl-4.pr-4
  .d-flex.align-center
    .top-fields
      soft-restore(:ID="model.ID" :status="model.status" v-if="isArchived").top-fields__item
      v-btn(
        small
        color='primary'
        @click="checkReservationQueueHandler"
        :disabled="disabledReservationQueue || isArchived"
        outlined
      ).top-fields__item Try to reserve
      v-btn(
        small
        color='primary'
        @click="$emit('parseReserveNumber', model.ID)"
        :disabled="disabledParseNumberButton || isArchived"
        outlined
      ).top-fields__item Parse Reservation Number
      mark-as-processed(:model="model" :disabled="disabledMarkAsProcessed || isArchived").top-fields__item
      wait-list-btn(:isWaitList.sync="model.isWaitList" v-show="isPageWithShowingWaitListBtn && !isArchived" :ID="model.ID").top-fields__item
      set-mijn(
        :ID="model.ID"
        v-if="canShowMijn && !isArchived"
        :defaultCourse="model.course"
        :status="model.status"
        :form="getMijnExamData"
      ).top-fields__item
      removeMIJN(:ID="model.ID" v-if="model.isMijnReservation && !isArchived").top-fields__item
      labels(:value="model.labels" hide-exclude @changed="updateLabels" v-if="showLabels && !isArchived").top-fields__item
        template(v-slot:activator="{on}")
          v-btn(color="primary" small v-on="on" outlined data-test="c-label-btn") Assign labels
      candidateArchive(:ID="model.ID" :disabled="model.disabledDelete" v-if="!isArchived").top-fields__item
      sync(:activeItems="[model]" v-if="!isArchived").top-fields__item
      add-to-landing(:ID="model.ID" :disabled="isArchived").top-fields__item
      restore(:ID="model.ID" :status="model.status" v-if="isArchived").top-fields__item
      candidate-mijn-switcher(:ID="model.ID" :add-mijn="true"  v-if="!model.isMijnReservation && (isCandidateNew || isCandidateAccepted)").top-fields__item
      candidate-mijn-switcher(:ID="model.ID" v-else-if="model.isMijnReservation && (isCandidateNew|| isCandidateAccepted)").top-fields__item
      canceled(:ID="model.ID" :canceled="model.canceled" @reservedExam:update="$emit('reservedExam:update')").top-fields__item
      send-certificate-email(:item="model" v-if="!isCandidateNew && !isCandidateAccepted && !isCandidateSubmitted").top-fields__item
      candidate-course-type(:courseType="model.course_type" :disabled="isArchived" @select="$emit('update:data', $event)").top-fields__item
      reapply(:ID="model.ID" :reapplyDateTime="model.force_reapply_allowed_until" :disabled="!isArchived").top-fields__item

  v-row.item-container.pt-3
    v-col(cols=6)
      h3.form-title.pb-4 Information
      .d-flex.align-center
        v-text-field(label="Candidate number"
          :disabled="isEditingDisabled"
          v-model="model.candidateNumber"
          @input="candidateNumberUpdating"
          type="number"
          name="candidatenumber"
          placeholder='1254232...'
          :readonly="disabled.candidateNumber || isArchived"
          :error="formErrors.hasError('candidateNumber')"
          :error-messages="formErrors.fields.candidateNumber"
        )
      .d-flex.align-center
        filter-autocomplete(
          :getItems="getCBRUser"
          item-value="ID"
          item-text="username"
          name="username"
          v-model="model.accountUsername"
          @input="valueUpdated('accountUsername', $event)"
          :disabled="!isCandidateNew || isEditingDisabled"
          label="CBR account"
        ).mr-4
        accept-activator(:model="model" :ID="model.ID" :disabled="isArchived || isEditingDisabled")

      filter-autocomplete(
        :getItems="getCBRStatus"
        label="CBR status"
        :disabled="disabled.cbrStatus || isArchived || isEditingDisabled"
        v-model="model.status"
        @input="valueUpdated('status', $event)"
      )
      div
        filter-autocomplete(
          v-if="!isProductNameDisabled"
          :disabled="isEditingDisabled"
          :getItems="getProductNames"
          v-model="isPastTab ? model.exam.productName : model.productName"
          @input="productNameUpdated"
          item-text="name"
          item-value="name"
          label="Product name"
          placeholder="Product name"
          :error="formErrors.hasError('productName')"
          :error-messages="formErrors.fields.productName"
        )
        v-text-field(
          v-else
          name="product"
          v-model="isPastTab ? model.exam.productName : model.productName"
          placeholder="Product Name"
          disabled
        )
      v-text-field(
        name="license_type"
        v-model="isPastTab ? model.exam.licenseType : model.licenseType"
        placeholder="License type"
        disabled
      )
    v-col(cols=6)
      h3.form-title.pb-4 Contacts
      v-text-field(
        label="Email"
        v-model="model.candidateEmail"
        :disabled="isArchived || isEditingDisabled"
        @input="valueUpdated('candidateEmail', $event)"
        :error="formErrors.hasError('candidateEmail')"
        :error-messages="formErrors.fields.candidateEmail"
      )
      v-text-field(
        label="Phone"
        v-model="model.candidateTel"
        @input="valueUpdated('candidateTel', $event)"
        :disabled="isArchived || isEditingDisabled"
        :error="formErrors.hasError('candidateTel')"
        :error-messages="formErrors.fields.candidateTel"
      )
      v-text-field(v-model="model.address" label="Address" disabled)
      v-checkbox(
        v-model="model.are_dates_fixed"
        @input="valueUpdated('are_dates_fixed', $event)"
        label="Fixed dates"
        hide-details
        :disabled="isArchived || isEditingDisabled"
      )
      v-checkbox(
        hide-details
        v-model="model.unsubscribe_from_suggestions"
        @input="valueUpdated('unsubscribe_from_subggestions', $event)"
        label="Unsubscribe from suggestions"
        :disabled="isArchived || isEditingDisabled"
      )

  v-row.item-container
    v-col(cols=6)
      v-text-field(v-model="model.mijn_exam_location" label="Mijn exam location" disabled)
    v-col(cols=6)
      v-text-field(v-model="mijnExamDateTime" label="Mijn exam datetime" disabled)

  h3.form-title Details
  courseChooseProvider(:item="model")
    template(v-slot:default="courseChoose")
      v-row.item-container
        v-col(cols=6)
          v-text-field(
            label="Nickname"
            v-model="model.candidateName" @input="valueUpdated('candidateName', $event)"
            data-test="c-edit-fullname"
            :readonly="isArchived || isEditingDisabled"
          )
        v-col(cols=6)
          v-text-field(
            v-model="model.candidateBirthday"
            label="Birthday"
            name="bday"
            @input="valueUpdated('candidateBirthday', $event)"
            data-test="candidate-item-bday"
            :readonly="isArchived || isEditingDisabled"
            :disabled="disabled.candidateBirthday"
            :error="formErrors.hasError('candidateBirthday')"
            :error-messages="formErrors.fields.candidateBirthday"
          )
        v-col(cols=6)
          v-text-field(
            label="First name"
            data-test="c-edit-first"
            @input="valueUpdated"
            v-model="model.firstName"
            :readonly="isArchived || isEditingDisabled"
            :error="formErrors.hasError('firstName')"
            :error-messages="formErrors.fields.firstName"
          )

        v-col(cols=6)
          v-text-field(v-model="model.drivingSchool" label="Driving school" disabled)
        v-col(cols=6)
          v-text-field(
            label="Last name"
            v-model="model.lastName"
            data-test="c-edit-last"
            @input="valueUpdated('lastName', $event)"
            :readonly="isArchived || isEditingDisabled"
            :error="formErrors.hasError('lastName')"
            :error-messages="formErrors.fields.lastName"
          )

        v-col(cols=6).exams-list
          candidate-exam-info(:exam="model.exam")
        v-col(cols=6)
          confirmed-courses-modal(:courses="model.confirmedCourses")
        v-col(cols=6)
          assign-candidates-to-journey(
            :activeItems="[model]"
            :disabled="!isAllowedAssignToJourney"
            :courseDayID="+$route.query.course_day"
            @updated="$emit('needToUpdate')"
          )
            template(v-slot:activator="createJourney")
              v-btn(@click="createJourney.open" :disabled="!isAllowedAssignToJourney" block).mt-3 Assign to Journey

  v-row.item-container.pt-3
    v-col(cols=12)
      h3.form-title Nutheorie online access | Duration: {{ getOnlineDifference }} days
    v-col(cols=6)
      v-text-field(label="Online start datetime" hide-details :value="onlineStartDateTime" readonly)
    v-col(cols=6)
      v-text-field(label="Online end datetime" hide-details :value="onlineEndDateTime" readonly)
    v-col(cols=6)
      v-text-field(v-model="model.package_name" label="Online package name" readonly)
    v-col(cols=6)
      v-text-field(v-model="model.package_paid_amount" label="Online paid amount" readonly)
    v-col(v-if="canManageOnlineAccess" cols=12)
      candidates-online-access(:model="model")

  v-row.item-container
    v-col(cols=12)
      h3.form-title Les online access
    v-col(cols=6)
      field-date-picker(
        v-model="model.dateExercisesAccessExpires"
        @input="valueUpdated('dateExercisesAccessExpires', $event)"
        label="Exercises access expires date"
        :min="currentDate"
        :hide-details="false"
        :disabled="isArchived"
      )
    v-col(cols=6)
      v-menu(
        :close-on-content-click="false"
        max-width="290px"
        min-width="290px"
        :disabled="!model.dateExercisesAccessExpires.length"
      )
        template(v-slot:activator="{ on, attrs }")
          v-text-field(:disabled="!model.dateExercisesAccessExpires.length" v-on="on" readonly v-model="model.timeExercisesAccessExpires" placeholder="Exercises access expires time")
        v-time-picker(format="24hr" v-model="model.timeExercisesAccessExpires" @input="valueUpdated('timeExercisesAccessExpires', $event)")

    v-col(cols=12)
      h3.form-title Wikitheorie access
    v-col(cols=6)
      field-date-picker(
        v-model="model.wiki_access_from_date"
        @input="valueUpdated('wiki_access_from_date', $event)"
        label="Wiki access granted from"
        :min="currentDate"
        :hide-details="false"
        :disabled="isArchived || isEditingDisabled"
      )
    v-col(cols=6)
      v-text-field(
        min="0"
        type="number"
        placeholder="0"
        label="Wiki access days"
        v-model="model.wiki_access_days"
        @input="valueUpdated('wiki_access_days', $event)"
        :readonly="isArchived || isEditingDisabled"
      )

    v-col(cols=6)
      h3.form-title Reservation days offset:
        span(v-if="hasSystemReservationOffset && !customOffsetValue" v-html="'&nbsp;can be reserved anytime'")
        span(v-if="!hasSystemReservationOffset && !customOffsetValue" v-html="'&nbsp;default system value'")
        span(v-if="customOffsetValue" v-html="'&nbsp;custom offset value'")
      v-text-field(
        min="0"
        v-model="model.reservation_days_offset"
        type="number"
        label="Set reservation days offset"
        :error="formErrors.hasError('reservation_days_offset')"
        :error-messages="formErrors.fields.reservation_days_offset"
        @input="valueUpdated('reservation_days_offset', $event || null)"
      )

    v-col(cols=6)
      v-text-field(
        :value="reapplicationDateFormat"
        :disabled="!reapplicationDateFormat"
        label="Reapplication is available until"
        readonly
      ).mt-6

  v-row.item-container
    v-col(cols=6)
      h3.form-title.pb-3 Reservation History
      history(:model="model" ref="tabs").item-container

    v-col(cols=6)
      h3.form-title.pb-3 Reservation details
      v-text-field(label="Reserved Location" hide-details :value="model.exam.location" disabled )
      v-text-field(label="Reserved Date" hide-details :value="model.exam.examDate" disabled)
      v-text-field(label="Reserved Code" hide-details :value="model.exam.code" disabled )
</template>

<script>
import Dayjs from "dayjs";
import { mapActions, mapGetters } from 'vuex'
import permsMixin from '@/mixins/perms.mixin'
import { convertToDefaultBackendFormat, convertToDefaultDateFormat } from '@/util'
import DisabledFields from "../core/models/candidateDisabled.js"
import {CBR_STATUS, CBR_STATUS_OPTIONS, LICENSE_TYPE_OPTIONS} from "../core/candidates-const"
import CandidateBroadcast from "../core/models/candidateBroadcast.js"
import Restore from "./CandidateRestore.vue"
import SoftRestore from "./CandidateSoftRestore.vue"
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import { COURSE_DAY_SIDEBAR_TABS } from '../../course_day/core/courseDay-const.js'
import {PRODUCT} from "@/util/const";

export default {
  name: 'CandidateItemFields',

  mixins: [permsMixin, featureFlagsMixin],

  props: {
    model: {
      type: Object,
      required: true
    },
    formErrors: Object,
    goBack: Function
  },

  data: () => ({
    CBR_STATUS,
    disabled: null,
    broadcast: null,
    currentDate: convertToDefaultBackendFormat(new Date(Date.now()))
  }),

  computed: {
    ...mapGetters({
      packagesList: 'packages/list'
    }),

    getOnlineDifference() {
      const date1 = this.$dates(this.model.package_starting_at)
      const date2 = this.$dates(this.model.package_ending_at)
      let diff = date2.diff(date1, 'day')
      if (!diff) return '-'
      return diff
    },

    reapplicationDateFormat() {
      if (!this.model.force_reapply_allowed_until) return
      return convertToDefaultDateFormat(this.model.force_reapply_allowed_until)
    },

    disabledParseNumberButton() {
      return this.model.status !== CBR_STATUS.RESERVED || (this.model.exam.code && this.model.exam.code.length > 0)
    },

    isProductNameDisabled() {
      if ([CBR_STATUS.NEW, CBR_STATUS.ACCEPTED].includes(this.model.status)) return false
      return true
    },

    disabledReservationQueue() {
      if ([CBR_STATUS.NEW, CBR_STATUS.ACCEPTED, CBR_STATUS.ARCHIVED, CBR_STATUS.RESERVED].includes(this.model.status)) return false
      return true
    },

    disabledCbrAccount() {
      if (this.disabled.cbrAccount) return true
      if (this.$route.name === this.$ROUTER_NAMES.CANDIDATES_TASKS) return true
      return false
    },

    disabledMarkAsProcessed() {
      if (this.hasReservedExam) return false
      if (this.$route.name === this.$ROUTER_NAMES.CANDIDATES_TASKS) return true
      return false
    },

    hasReservedExam () {
      return !!this.model.exam.location
    },

    isPageWithShowingWaitListBtn() {
      if (this.$route.name === this.$ROUTER_NAMES.CANDIDATES_RESERVE_EXCHANGE) return false
      if (this.$route.name === this.$ROUTER_NAMES.CANDIDATES_RESERVED_EXCHANGED) return false
      if (this.$route.name === this.$ROUTER_NAMES.COURSE_MANAGEMENT) return false
      return true
    },

    canShowMijn() {
      return !!(([CBR_STATUS.NEW, CBR_STATUS.ARCHIVED].includes(this.model.status)) || this.model.isMijnReservation);
    },

    isAutoProduct() {
      return this.model.productName === PRODUCT.BTH || this.model.productName === PRODUCT.BTH_VE;
    },

    isCandidateNew() {
      return this.model.status === CBR_STATUS.NEW
    },

    isCandidateAccepted() {
      return this.model.status === CBR_STATUS.ACCEPTED
    },

    isCandidateSubmitted() {
      return this.model.status === CBR_STATUS.SUBMITED
    },

    showLabels() {
      return this.$route.name !== this.$ROUTER_NAMES.COURSE_MANAGEMENT;
    },

    isArchived() {
      return this.model.status === CBR_STATUS.ARCHIVED
    },

    isPastTab() { // Its for new course page
      return this.$route.query.tab_name === COURSE_DAY_SIDEBAR_TABS.PAST
    },

    isEditingDisabled() {
      return this.isPastTab
    },

    isAllowedAssignToJourney() {
      return this.model.status === CBR_STATUS.ARCHIVED || this.model.status === CBR_STATUS.RESERVED;
    },

    reservationOffset() {
      return this.model.reservation_days_offset
    },

    customOffsetValue() {
      return !!(this.model && parseInt(this.reservationOffset) > 1);
    },

    hasSystemReservationOffset() {
      let isNull = this.reservationOffset === null
      let isSystemValue = parseInt(this.reservationOffset) <= 1
      return !!(this.model && !isNull && isSystemValue);
    },

    mijnExamDateTime: {
      get() {
        if (!this.model.mijn_exam_datetime) return null
        return Dayjs(new Date(this.model.mijn_exam_datetime).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})).format('DD-MM-YYYY HH:mm')
      },
      set(val) {
        this.model.mijn_exam_datetime = val
      }
    },

    getMijnExamData() {
      if (!this.model.mijn_exam_location || !this.model.mijn_exam_datetime) return {}

      return {
        location: this.model.mijn_exam_location,
        date: this.$dates(new Date(this.model.mijn_exam_datetime).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})).format('DD-MM-YYYY'),
        time: this.$dates(new Date(this.model.mijn_exam_datetime).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})).format('HH:mm')
      }
    },

    hasPackage() {
      return !!(this.model && this.model.has_package);
    },

    onlineStartDateTime: {
      get() {
        if (!this.model.package_starting_at) return null
        return Dayjs(new Date(this.model.package_starting_at).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})).format('DD-MM-YYYY HH:mm')
      },
      set(val) {
        this.model.package_starting_at = val
      }
    },

    onlineEndDateTime: {
      get() {
        if (!this.model.package_ending_at) return null
        return Dayjs(new Date(this.model.package_ending_at).toLocaleString('en-US', {timeZone: 'Europe/Amsterdam'})).format('DD-MM-YYYY HH:mm')
      },
      set(val) {
        this.model.package_ending_at = val
      }
    },
  },

  created() {
    this.initDisabled()
    this.initCandidateBroadcast()
  },

  methods: {
    ...mapActions({
      getCBRUser: 'cbrUser/list',
      getCRMCourseCities: 'crmCourseCities/list',
      getProductNames: 'crmProductNames/list'
    }),

    initDisabled() {
      this.disabled = new DisabledFields()
    },

    async initCandidateBroadcast() {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh()
      this.broadcast = new CandidateBroadcast(this.disabled, tokens.access)
      this.broadcast.startListen(this.getBroadcastData, this.onReservedExamUpdate)
    },

    getBroadcastData() {
      return [this.model]
    },

    getCBRStatus() {
      return CBR_STATUS_OPTIONS
    },

    getLicenseTypes() {
      return LICENSE_TYPE_OPTIONS;
    },

    checkReservationQueueHandler() {
      this.$emit('checkReservationClicked')
    },

    candidateNumberUpdating(val) {
      this.model.candidateNumber = val
      this.valueUpdated('candidateNumber', val)
      this.$emit('candidateNumberChanged')
    },

    valueUpdated(field, val) {
      this.model.editing = true
      if (field) this.changeField({field, val})
    },

    changeField({field, val}) {
      this.$emit('change:field', {field, val})
    },

    onReservedExamUpdate(candidate) {
      if(!candidate.exam || candidate.exam.reservation_code == this.model.exam.code) return
      this.$emit('reservedExam:update')
    },

    updateLabels(labels) {
      this.$emit('labelsChanged', labels)
    },

    productNameUpdated() {
      this.model.editing = true
      if (this.isPastTab) this.changeField('exam', this.model.exam)
      else this.changeField('productName', this.model.productName)
    },

    selectPackage(value) {
      this.model.package_name = value
      this.valueUpdated('package_name', value)
      //
      this.model.package_info = this.packagesList.find(item => item.name === value) || null
    }
  },

  components: {
    assignCandidatesToJourney: () => import('@/app/admin/modules/course_day/components/journey/JourneyAssignCandidates.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    markAsProcessed: () => import('./CandidateItemRemoveFromQueue.vue'),
    history: () => import('./CandidateItemTab.vue'),
    waitListBtn: () => import('./item/CandidatesWaitBtn.vue'),
    acceptActivator: () => import('./CandidateAcceptActivator.vue'),
    setMijn: () => import('./CandidatesSetMijn.vue'),
    labels: () => import('./CandidatesCustomLabels.vue'),
    courseChooseProvider: () => import('./reservation/CandidateCourseChooseProvider.vue'),
    removeFromAccept: () => import('./CandidateItemRFA.vue'),
    candidateArchive: () => import('./CandidatesArchive.vue'),
    removeMIJN: () => import('./item/CandidateRemoveMijn.vue'),
    sync: () => import('./CandidatesSync.vue'),
    addToLanding: () => import('./CandidatesAddToLanding.vue'),
    canceled: () => import('./CandidatesCanceled.vue'),
    reapply: () => import('./CandidatesReapply.vue'),
    fieldDatePicker: () => import('@/components/global/FieldDatepicker.vue'),
    confirmedCoursesModal: () => import('@/app/admin/modules/candidates/components/item/CandidateConfirmedCoursesModal.vue'),
    candidateMijnSwitcher: () => import('./item/CandidateMijnSwitcher.vue'),
    sendCertificateEmail: () => import('./item/CandidateSendCertificate.vue'),
    candidateExamInfo: () => import('./item/CandidateExamInfo'),
    candidateCourseType: () => import('@/app/admin/modules/candidates/components/item/CandidateCourseType.vue'),
    datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    candidatesOnlineAccess: () => import('./CandidatesOnlineAccess.vue'),
    Restore,
    SoftRestore,
  }
}
</script>

<style lang="scss" scoped>
.top-fields {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    margin-top: 8px;
    margin-right: 8px;
  }
}

.exams-list {
  display: flex;
  align-items: center;
}
</style>
