<template lang="pug">
div
  .history
    .history__wrapper
      .history__item(:class="{'history__item_active': isAccepted}")
        v-icon(x-small color="white") mdi-check
      h4.history__title(:class="{'history__title_active': isAccepted}") accepted
    .history__wrapper
      .history__item(:class="{'history__item_active': isReserved}")
        v-icon(x-small color="white") mdi-check
      h4.history__title(:class="{'history__title_active': isReserved}") reserved
        
    .history__wrapper
      .history__item(:class="{'history__item_active': isExchanged}")
        v-icon(x-small color="white" ) mdi-check
      h4.history__title(:class="{'history__title_active': isExchanged}") exchanged
      
</template>

<script>
import {CBR_STATUS} from "../core/candidates-const"
export default {
  name: 'CandidateTab',
  props: {
    model: Object
  },

  data: () => ({
    reservationTab: null,
    tabOptions: [
      {label: 'Reservation', disabled: false, key: 'reserve'},
      {label: 'Exchange', disabled: false, key: 'exchange'}
    ]

  }),

  computed: {
    isAccepted() {
      if([CBR_STATUS.ACCEPTED, CBR_STATUS.RESERVED].includes(this.model.status)) return true
      if(this.model.wasExchanged) return true
      return false
    },
    isReserved() {
      if(this.model.status === CBR_STATUS.RESERVED) return true
      if(this.model.wasExchanged) return true
      return false
    },
    isExchanged() {
      if(this.model.wasExchanged) return true
      return false
    },
  },

  methods: {
    setExamTab() {
      if(this.model.hasReservedExam) {
        this.reservationTab = 1
        this.tabOptions[0].disabled = true
      }
      else {
        this.reservationTab = 0
        this.tabOptions[1].disabled = true
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.history {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 52px;
    &:last-child {
      padding-bottom : 0;
      .history__item {
        &:after {
          height: 0;
        }
      }
    }
    // &::after {
    //   position: absolute;
    //   content: '';
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   margin: 0 auto;
    //   height: 100%;
    //   width: 1px;
    //   background-color: $border-secondary;
    // }
  }
  &__title {
      font-weight: 500;
      text-transform: uppercase;
      color: $border-secondary;
      line-height: normal;
      &_active {
        color: $active-success;
      }
  }
  &__item {
    width: 23px;
    height: 23px;
    margin-right: 14px;
    border: 1px solid $border-secondary;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      position: absolute;

      content: '';
      top: 22px;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 52px;
      width: 1px;
      background-color: $border-secondary;
    }
    &_active {
      background-color: $active-success;
      border-color: $active-success;
    }
  }
}
</style>