import { render, staticRenderFns } from "./CandidateItemFields.vue?vue&type=template&id=62f8ff9d&scoped=true&lang=pug&"
import script from "./CandidateItemFields.vue?vue&type=script&lang=js&"
export * from "./CandidateItemFields.vue?vue&type=script&lang=js&"
import style0 from "./CandidateItemFields.vue?vue&type=style&index=0&id=62f8ff9d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f8ff9d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VBtn,VCheckbox,VCol,VMenu,VRow,VTextField,VTimePicker})
