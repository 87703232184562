import { FREE_STATUSES } from '../candidates-const';

export default class CandidateDisabled {
  constructor() {
    this.candidateNumber = false;
    this.cbrAccount = false;
    this.candidateBirthday = false;
    this.cbrStatus = true;
    this.wasExchanged = true;
    this.failed = true;
    this.onHold = false;
  }

  checkCBRStatus(status) {
    let isFreeStatus = FREE_STATUSES.some(_status => _status === status);
    if (isFreeStatus) return;
    this.candidateNumber = true;
    this.candidateBirthday = true;
    this.cbrAccount = true;
  }
}
