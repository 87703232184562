<template lang="pug">
span

  v-btn(
    @click="showModalHandler" 
    :loading="loading"
    small
    color="primary"
    outlined
  ) Zet kandidaat terug op onze kosten
  confirmation-dialog(
    v-model="modalShowing"
    :title="modalTitle"
    :descr="modalText"
    @okBtnClicked="send"
    @failBtnClicked="reset"
    @click:outside="reset"
  )
</template>

<script>
import ErrMixin from "@/mixins/errors.mixin"
import { CBR_STATUS } from '../core/candidates-const'
export default {
  name: 'CandidateRemoveFromAcceptance',
  mixins: [ErrMixin],
  inject: ['svc'],

  props: {
    disabled: Boolean,
    ID: Number,
    status: String
  },

  data: () => ({
    loading: false,
    modalTitle: 'Zet kandidaat terug op onze kosten',
    modalText: 'Are you sure you want to soft reset this candiidate?',
    modalShowing: false
  }),

  computed: {
    Svc() {
      return this.svc()
    },

    disabledAction() {
      if(this.disabled) return true
      if(this.status !== CBR_STATUS.ARCHIVED) return true
      return false
    }

  },

  methods:{ 
    showModalHandler() {
      this.showConfirmation()
    },

    showConfirmation() {
      this.freeze()
      this.modalShowing = true
    },

    async send() {
      this.freeze()
      try {
        await this.Svc.softRestore(this.ID)
        this.unFreeze()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.unFreeze()
      }
    },

    freeze() {
      this.loading = true
    },

    unFreeze() {
      this.loading = false
    },

    reset() {
      this.unFreeze()
    }
  },

  components: {
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>